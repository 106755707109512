import { SeriesLegendItemClickEventObject } from "highcharts";
import {
  all,
  find,
  invertObj,
  isEmpty,
  isNil,
  keys,
  map,
  pathOr,
  pipe,
  pluck,
  propEq,
  values,
} from "ramda";

import { DataSeriesModel, DataSeriesResponse } from "types";

import { formatNumberWithMagnitude, NumberMagnitude } from "./numberFormatter";
import { normalizeName } from "./textFormat";

export const procedureFormatter = (props: any) => `${props.value ? props.value / 1000 : 0}`;

export const formatLabel = (value: number, isPercent: boolean, decimal = 1) => {
  if (!isNil(value) && !isEmpty(value)) {
    if (Math.abs(value) < (1 / 10 ** (decimal + 1)) * 5) {
      value = 0;
    }

    if (isPercent) {
      let fixedValue = Number.isFinite(value) ? `${value.toFixed(decimal)}%` : "-";
      if (decimal === 0 && value < 0 && value > -0.5) {
        fixedValue = fixedValue.replace("-", "");
      }
      return fixedValue;
    }

    return formatNumberWithMagnitude(value, decimal);
  }
  return "-";
};

export const emptySeriesCheck = (series: DataSeriesResponse) =>
  isEmpty(series) || all((key) => isEmpty(series.data[key]), keys(series.data));

export const chartSeriesToModel = (
  series: DataSeriesResponse,
  prop: string,
  dataKeys?: string[]
): DataSeriesModel => {
  if (!dataKeys) {
    dataKeys = keys(series.data);
  }
  return {
    ...series,
    data: map(
      (key) => ({
        name: normalizeName(key),
        y: pathOr(null, ["data", key, prop], series),
        meta: pathOr(null, ["data", key], series),
      }),
      dataKeys
    ),
  };
};

export const magnitudeMap = {
  B: 1000000000,
  M: 1000000,
  k: 1000,
};

export const magnitudeMapToNumber = invertObj(magnitudeMap);

export const getMagnitude = (value: number): NumberMagnitude => {
  switch (true) {
    case value > magnitudeMap["B"]:
      return "B";
    case value > magnitudeMap["M"]:
      return "M";
    case value > magnitudeMap["k"]:
      return "k";
    default:
      return "";
  }
};

export const getDecimal = (value: number, magnitude: NumberMagnitude) => {
  switch (true) {
    case magnitude !== "" && value > 10 * magnitudeMap[magnitude]:
      return 1;
    case magnitude !== "":
      return 2;
    default:
      return 0;
  }
};

export const getSeriesMagnitudeAndDecimal = (
  series: DataSeriesModel[] | undefined,
  sumUpValues = true
): { magnitude: NumberMagnitude; decimal: number } => {
  if (isNil(series) || isEmpty(series)) return { magnitude: "", decimal: 0 };
  const maxValue = Math.max(
    ...pipe<any[], DataSeriesModel["data"][], Record<string, number>, number[]>(
      pluck("data"),
      (data) => {
        const periods = pluck("name", data[0]);
        return periods.reduce((acc, curr) => {
          if (isNil(acc[curr])) {
            acc[curr] = 0;
          }
          map((dataSeries) => {
            const periodData = find(propEq("name", curr), dataSeries);
            const periodValue = periodData?.y || 0;
            if (sumUpValues) {
              acc[curr] += periodValue;
            } else {
              acc[curr] = periodValue > acc[curr] ? periodValue : acc[curr];
            }
          }, data);
          return acc;
        }, Object.assign({}) as Record<string, number>);
      },
      values
    )(series)
  );
  const magnitude = getMagnitude(maxValue);
  return { magnitude, decimal: getDecimal(maxValue, magnitude) };
};

export const updateMagnitudeOnLegendClick = (e: SeriesLegendItemClickEventObject) => {
  const {
    chart: { series },
    index: targetedIndex,
  } = e.target;
  const visibleSeries = series.filter(
    ({ visible }, idx) => (visible && idx !== targetedIndex) || (!visible && idx === targetedIndex)
  );
  return getSeriesMagnitudeAndDecimal(visibleSeries as any);
};
